import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    { url: '/lifestyle-choices', title: 'LIFESTYLE', icon: 'soft-red-nav-icon.png', iconHover: 'soft-green-nav-icon.png' },
    { url: '/phases-of-care', title: 'CARE', icon: 'soft-red-nav-icon.png', iconHover: 'soft-green-nav-icon.png' },
    { url: '/blog', title: 'BLOG', icon: 'soft-red-nav-icon.png', iconHover: 'soft-green-nav-icon.png' },
    { url: '/faq', title: 'FAQ', icon: 'soft-red-nav-icon.png', iconHover: 'soft-green-nav-icon.png' },
    { url: '/expectations', title: 'EXPECTATIONS', icon: 'soft-red-nav-icon.png', iconHover: 'soft-green-nav-icon.png' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  return (
    <>
      <nav className="bottomMenu">
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
    </>
  );
}