const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorYellow : '#eee8dc',
  colorBlue : '#007bff',
  colorBlue2 : '#707391',
  colorGrayBlue : '#6f7393',
  colorCream : '#efe8da',
  colorSoftRed : '#e2a59f',
  colorSoftGreen : '#99c6c1'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"trajan-sans-pro",serif',

  primaryColor: colors.colorSoftRed,
  fontColor: colors.colorGrayBlue,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;